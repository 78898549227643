.auto-messages {
    a {
        cursor: pointer;
        color: #007bff !important;
        &:hover {
            text-decoration: underline !important;
        }
    }

    .placeholder-tips {
        li {
            list-style: none;
        }
    }
}

.auto-message-details {
    .bottom-button-panel {
        button {
            margin-right: 15px;
        }
    }
}

.auto-messages-list {
    .command-panel {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        .command-panel-group {
            display: flex;
            align-items: center;
            margin-right: 10px;
            &:last-child {
                width: 30%;
                margin-right: 0px;
            }

            div:first-child {
                margin-right: 10px;
            }
        }
    }

    .sort {
        margin-left: 5px;
    }
    .sort-desc {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        display: inline-block;
    }
    th:hover {
        cursor: pointer;
    }
}