.plan-new-overview {
    margin-top: 36px;

    th {
        width: 12.5%;
    }
    td {
        padding: 0.1rem !important;
    }

    .workout {
        &__type {
            padding-bottom: 0.6em;
            & .badge {
                margin-right: 0.6em;
            }
        }

        &__generator {
            color: #9e9e9e;
            font-size: 67.5%;
        }

        &__distance {
            color: #9e9e9e;
            white-space: nowrap;
            font-size: 67.5%;
            margin-right: 0.6em;
        }
    
        &__desc {
            padding-bottom: 0.6em;
            font-size: 87.5%;
            white-space: pre-wrap;
        }

        &__links {
            margin: 0px;
            padding: 0px;
            padding-bottom: 0.6em;
            font-size: 87.5%;
            white-space: pre-wrap;
            display: flex;

            li {
                list-style: none;
                margin-right: 5px;
            }
        }
    }
}

// decrease size controls
.plans-new-view {
    .btn {
        padding: .175rem .75rem;
    }

    input.form-control {
        height: calc(1.5em + .25rem + 2px);
    }

    .input-group-text {
        padding: 0 .75rem;
    }

    .col-form-label {
        padding-top: calc(.25rem + 1px);
        padding-bottom: calc(.25rem + 1px);
    }
}