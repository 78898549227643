.users{
    &__actions {
        margin-bottom: 16px;

        .btn {
            margin-right: 12px;

            > svg {
                height: 18px!important;
            }
        }
    }

    &__list {
        overflow: auto;
        display: table;

        &__icon {
            width: 16px;
        }

        &__row {
            height: 50%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.last {
                top: 50%;
            }
        }
    }

    &__table {
        width: auto!important;
        overflow: unset!important;
        position: relative;
        display: table;

        .rdt_TableHead {
            .rdt_TableCol {
                color: #fff;
                background-color: #343a40;
                border-color: #454d55;

                .rdt_TableCol_Sortable {
                    color: #fff!important;
                }
            }
        }

        .rdt_TableRow {
            border-bottom: 1px solid rgba(0,0,0,.12)!important;
        }

        .rdt_TableCell {
            font-size: 0.9em;
        }

        .rdt_Pagination {
            -webkit-justify-content: flex-start!important;
            -ms-flex-pack: start!important;
            justify-content: flex-start!important;
            border-top-width: 0!important;
        }
    }

    &__filter {
        .btn {
            margin-right: 12px;
        }

        h4 {
            margin: 12px 0;
        }
    }
}

.users-message-send
{
    .wrapper {
        width: 50%;

        .btn-margin-left {
            margin-left: 20px;
        }
    }
}
