.blog {
    a {
        cursor: pointer;
        color: #007bff !important;
        &:hover {
            text-decoration: underline !important;
        }
    }

    .placeholder-tips {
        li {
            list-style: none;
        }
    }
}

.blog-list {
    .command-panel {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        .command-panel-group {
            display: flex;
            align-items: center;
            margin-right: 10px;
            &:last-child {
                width: 30%;
                margin-right: 0px;
            }

            div:first-child {
                margin-right: 10px;
            }
        }
    }
}

.blog-edit {
    .editor-container {
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        padding: 7px;
        min-height: 150px;
    }

    .bottom-button-panel {
        button {
            margin-right: 15px;
        }
    }
}