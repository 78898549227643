.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: calc( 50% - ( 36px / 2) ); 
    right: calc( 50% - ( 36px / 2) ); 
    width: 36px;
    height: 36px;
}

.spinner.absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    right: unset;
    margin-top: -18px;
    margin-left: -18px;
}

.show-more-link {
  font-weight: 600;
  font-size: 80%;
}