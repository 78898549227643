.plan-overview {
    margin-top: 36px;
}

.plan-day {
    width: 14%;
}

.workout {
    &__type {
        padding-bottom: 0.6em;
    }

    &__desc {
        padding-bottom: 0.6em;
    }

    &__distance {
        font-weight: bold;
    }
}
