$green: #86BB71;
$blue: #94C2ED;
$orange: #E38968;
$gray: #92959E;

.users-profile {
    form .w-50 {
        &:first-child {
            padding-right: 20px;
        }

        &:last-child {
            padding-left: 20px;
        }
    }

    > .btn {
        margin-left: 20px;
    }

    .clear-both {
        clear: both;
    }

    .goal-race {
        .react-datepicker-wrapper {
            flex: 1.5 1;

            .form-control {
                border-radius: 0!important;
            }
        }
    }

    &-messages, &-trainings, &-survey {
        .message-day {
            > span {
                display: inline-block;
                border-radius: 10px;
                color: #fff;
                background-color: #004085;
                border-color: #b8daff;
            }
        }

        .plan-parameter {
            background-color: #ddd;
            color: #000;
            border: 1px solid #aaa;
            border-radius: 10px;
        }
    }

    &-messages {
        .message-data {
            margin-bottom: 15px;

            &-time {
                color: lighten($gray, 10%);
                padding-left: 6px;
            }

            &-name {
                padding: 0 10px;
            }

            &-coach, &-bot {
                text-align: right;
            }
        }

        .message-text {      
            color: white;
            padding: 18px 20px;
            line-height: 26px;
            font-size: 16px;
            border-radius: 7px;
            margin-bottom: 16px;
            width: 90%;
            position: relative;
            background-color: $green;
            margin-left: 10%;
            
            &:after {
                bottom: 100%;
                left: 7%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-bottom-color: $green;
                border-width: 10px;
                margin-left: -10px;
            }

            &-user {
                margin-left: 0;
            }

            &-coach {
                background-color: $orange;
                &:after {
                    border-bottom-color: $orange;
                    left: unset;
                    right: 7%;
                }
            }

            &-bot {
                background-color: $blue;
                &:after {
                    border-bottom-color: $blue;
                    left: unset;
                    right: 7%;
                }
            }
        }
    }

    &-survey {
        .survey-type {
            span {
                padding: 0 6px;
                display: inline-block;
                margin-left: 6px;
            }
        }
    }

    &-calendar {
        float: left;
        padding: .375rem .75rem;
        line-height: 1.5;
    }

    &-races {
        .race__table {
            width: auto!important;
            overflow: unset!important;
            position: relative;
            display: table;
    
            .rdt_TableHead {
                .rdt_TableCol {
                    color: #fff;
                    background-color: #343a40;
                    border-color: #454d55;
    
                    .rdt_TableCol_Sortable {
                        color: #fff!important;
                    }
                }
            }
    
            .rdt_TableRow {
                border-bottom: 1px solid rgba(0,0,0,.12)!important;
            }
    
            .rdt_TableCell {
                font-size: 0.9em;
            }
    
            .rdt_Pagination {
                -webkit-justify-content: flex-start!important;
                -ms-flex-pack: start!important;
                justify-content: flex-start!important;
                border-top-width: 0!important;
            }
        }
    }

    &-new-plan {
        .parameters-wrapper {
            max-width: 500px;
        }
    }

    .device-info {
        padding-top: 10px;
        span {
            margin-right: 10px;
        }
    }

    .athlete-parameters-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        .column {
            width: 50%;
        }

        .athlete-parameter {
            display:flex;
            div:first-child {
                width: 40%;
            }
        }
    }

    .sub-row {
        border: 0px;
        td {
            border: 0px;
        }
    }

    &-charts {
        .tools-container {
            display: flex;
            justify-content: center;
            margin: 20px 0px;
            padding: 20px 10px;
            border: rgba(237,242,249,0.96) solid 1px;

            .tools-group {
                display: flex;
                align-items: center;
                margin-right: 20px;
                
                &:last-child {
                    margin-right: 0px;
                }

                div:first-child {
                    margin-right: 10px;
                    font-weight: 600;
                }
            }
        }

        dl.tooltip-container {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 4px;
            border-radius: 5px;
            background-color: rgba(237,242,249,0.96);
            padding: 6px;
            max-width: 350px;
        }
    
        dt.definition-term {
            display: inline-block;
            margin-bottom: 0;
            font-weight: normal;
    
            &:after {
                content: ':';
            }
        }
    
        dt.definition-data {
            display: inline-block;
            font-weight: bold;
            margin-bottom: 0;
        }
    }
}