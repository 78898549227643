@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.fc-event {
    cursor: pointer;
}

.fc-title {
    color: #fff !important;
}

a.fc-day-number:hover {
    text-decoration: underline !important;
    font-weight: bold !important;
}

//.fc-body .fc-row { height: auto!important; }

.training {
    cursor: pointer;
    color: #fff !important;
    background-color: #999;
    border: 0;
    padding: 8px;

    &--default {
        height: 72px;
    }

    &--extended {
        height: 120px;
    }

    &__type {
        font-weight: bold;
        text-decoration: underline;
    }

    &__completion-param {
        font-weight: bold;
        font-size: 110%;
    }
}

.emptyDay {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.emptyDay #emptyDayContent {
    visibility: hidden;
}

.emptyDay:hover #emptyDayContent {
    visibility: visible;
}

.modify-training-modal {
    @media (min-width: 900px) {
        .modal-dialog {
            max-width: 800px;
            margin: 1.75rem auto;
        }
    }

    .btn-reset {
        margin-top: 10px;
    }

    .error {
        border: solid 1px red;
        border-radius: 4px;
    }
    .error-text {
        color: red;
        font-size: smaller;
    }

    .training-date-container {
        width: 200px;
        .react-datepicker-wrapper {
            width: 100%;
        }
    }
    .training-name-container,
    .coaching-type-container {
        width: 200px;
    }
    .training-description-container {
        margin-top: 10px;
        font-size: 14px;
    }
    .training-placeholders {
        color:#808080;
        font-size: smaller;
        .span {
            font-weight: 600;
        }
    }

    .files-container {
        width: 100%;
        margin-top: 10px;
    
        .file-table {
            width: 100%;
    
            tbody {
                tr {
                    border-top: 1px solid #ccc;
                }
    
                td {
                    vertical-align: top;
                }
    
                .middle {
                    word-break: break-all;
                }
    
                td:first-child {
                    width: 20%;
                }
    
                td:last-child {
                    text-align: right;
                }
    
                a {
                    cursor: pointer;
                }
            }
        }
    }

    .activity-link {
        font-size: 14px;
    }

    .modal-title-fullwidth {
        width: 100% !important;
    }
    .modal-title-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.params-zones {
    margin-bottom: -65px;
    .training-indicator + .training-indicator {
        margin-left: 0.5em;
    }
    .param-zones-title {
        width: 30px;
        display: inline-block;
        font-size: 13px;
    }
    .zone-item {
        font-size: 13px;
        width: 46px;
        height: 30px;
        display: inline-block;
        text-align: center;
        .type {
            font-size: 9px;
            text-align: center;
            width: 100%;
            float: left;
        }
    }
}

.duration-picker-form-control {
    input[type='text'] {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
}

.chart-wrapper {
    padding-top: 20px;

    dl.tooltip-container {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 4px;
        border-radius: 5px;
        background-color: rgba(237,242,249,0.96);
        padding: 6px;
    }

    dt.definition-term {
        display: inline-block;
        margin-bottom: 0;
        font-weight: normal;

        &:after {
            content: ':';
        }
    }

    dt.definition-data {
        display: inline-block;
        font-weight: bold;
        margin-bottom: 0;
    }
}

.training-export-wrapper {
    text-align: center;
    margin: 10px;

    .table > tbody > tr > td {
        vertical-align: middle;
        height: 70px;

        &.app-name {
            font-size: 1rem;
            font-weight: bold;
        }
   }
}