.nav .nav-link {
    font-size: 16px;
} 

.nav .nav-item {
    margin-bottom: 8px;
}

.nav-link {
    display: flex;
    align-items: center;
}

.nav__icon {
    margin-bottom: 2px;
    margin-right: 8px;
}

.mobile-menu-button-container {
    position: fixed;
    top: 0px;
    // left: 310px;
    z-index: 2000;
  }