.list-pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .per-page {
        display: flex;
        align-items: center;
        margin-right: 10px;
        margin-bottom: 1rem;
        span {
            padding-right: 5px;
        }
    }
}