.surveys {
    .content-wrapper {
        margin-top: 20px;
    }

    .table-col-cmd {
        a {
            cursor: pointer;
            color: #007bff !important;
            margin-right: 15px;
    
            &:hover {
                text-decoration: underline !important;
            }
        }
    }

    .active-checkbox-wrapper {
        input[type=checkbox] {
            width: 30px;
            height: 30px;
        }
    }

    .definition-block-edit {
        border: 2px solid #000 !important;
        background-color: #fffce2;
    }

    .definition-block {
        margin-top: 20px;
        margin-bottom: 40px;
        padding: 15px;
        border: 2px solid #d4d4d4;
        border-radius: 10px;

        .questionText {
            font-weight: 700;
            font-size: 1rem;
        }

        .bold {
            font-weight: 600;
        }

        .btn-panel {
            margin-top: 15px;
            margin-bottom: 5px;
            display: flex;
            justify-content: space-between;
            a {
                cursor: pointer;
                margin-right: 15px;
                color: #007bff;
            }
        }

        .col-bordered {
            border-left: 1px solid #d4d4d4;
            border-right: 1px solid #d4d4d4;
        }
    }

    .split-col {
        display: flex;
        justify-content: space-between;
        //align-items: center;
        margin-bottom: 15px;

        .filter {
            display: flex;
            align-items: center;

            .add-link {
                min-width: 100px;
            }

            & > div {
                margin-right: 10px;
            }
            .filter-survey-type {
                display: flex;
                align-items: center;
                width: 100%;
                div:first-child {
                    margin-right: 10px;
                }
                & > div:last-child {
                    min-width: 250px;
                }
            }
        }
    }

    .option-delete-link {
        margin-top: 33px;
         a {
            cursor: pointer;
            color: #007bff !important;
         }
    }

    .error-summary {
        margin-top: 20px;
        margin-bottom: 20px;
        color: red;
    }

    .btn-panel {
        margin-bottom: 45px;
    }
}