.data-table-component {
    .sort {
        margin-left: 5px;
    }
    .sort-desc {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        display: inline-block;
    }
    th:hover {
        cursor: pointer;
    }
    a {
        cursor: pointer;
        color: #007bff !important;
        &:hover {
            text-decoration: underline !important;
        }
    }

}