.bot-stats {
    .date {
        white-space: nowrap;
    }

    .download {
        float: right;
    }

    .weekend {
        color: #d00;
    }

    .table-dashboard {
        th, td {
            text-align: center;
        }

        tr.expanded-row {
            td {
                border-top: none;

                div {
                    margin-bottom: 10px;
                }
            }
        }
    }
}