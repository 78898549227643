.omy-chat {
    a {
        cursor: pointer;
        color: #007bff !important;
        margin-right: 15px;
    }

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .loader {
        position: fixed;
    }

    .mobile-users-button-container {
        display: flex;
        margin-bottom: 5px;
        justify-content: center;
    }
      
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .chat-container {
        display: flex;
        width: 100%;
        height: calc(88vh - 133px);

        @media (min-width: 768px) {
            & {
                height: calc(90vh - 48px);
            }
        }

        .user-list-container {
            min-width: 22%;
            padding-right: 10px;
            display: flex;
            flex-direction: column;

            .search-panel {
                ul.role-selector {
                    margin: 10px 0px 0px 0px;
                    padding: 0px;
                    display: flex;
                    justify-content: space-between;
                    li {
                        display: inline-block;
                        list-style: none;
                        margin-right: 10px;

                        label {
                            margin-left: 5px;
                        }
                    }
                }
            }

            .user-list {
                margin-top: 20px;
                overflow-y: scroll;
                position: relative;

                .user-item {
                    margin: 5px 0px;
                    border-bottom: 1px solid rgba(0,0,0,.1);
                    padding: 10px 0px 10px 5px;

                    &:hover, &.active {
                        background-color: #f1f1f1;
                    }

                    .name {
                        font-weight: 500;
                    }
                    .email {
                        font-weight: 300;

                    }
                    .stats {
                        font-size: 11px;
                    }

                    .stats-bold {
                        font-weight: 600;
                    }
                }
            }

            .btn-panel{
                margin-top: auto;
                padding-top: 20px;
                
                .title {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 10px;
                    font-weight: 600;
                }

                .buttons {
                    display: flex;
                    justify-content: space-evenly;
                }
            }
        }
    
        .message-list-container {
            width: 100%;
            border-right: 1px solid rgba(0,0,0,.1);
            border-left: 1px solid rgba(0,0,0,.1);
            padding: 0px 10px;
            display: flex;
            flex-direction: column;

            .no-conversation {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .messages-list {
                overflow-y: scroll;
                position: relative;
                margin-top: 16px;
                margin-bottom: 20px;
                display: flex;
                flex-direction: column-reverse;

                .is-yours {
                    justify-content: flex-end !important;
                }

                .reverse {
                    flex-direction: row-reverse;
                    justify-content: flex-start;

                    .message-content {
                        padding-right: 10px;
                    }
                }

                .message-item {
                    display: flex;
                    justify-content: flex-start;
                    padding-right: 10px;
                    margin-bottom: 25px;
                    width: 100%;

                    .content-wrapper {
                        display: flex;
                        width: 90%;
                        align-items: flex-start;
                        
                        .avatar {
                            display: flex;

                            img {
                                border-radius: 50%;
                                max-width: 50px;
                                max-height: 50px;
                            }

                            .text-avatar {
                                border-radius: 50%;
                                width: 50px;
                                height: 50px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 16px;
                            }
                        }

                        .message-content {
                            display: flex;
                            padding-left: 10px;
                            flex-direction: column;
                            max-width: 100%;

                            .message-body {
                                border-radius: 7px;
                                font-size: 16px;
                                padding: 12px 10px;
                                min-width: 150px;
                                white-space: pre-line;
                            }

                            .message-info {
                                font-size: 12px;
                                padding-left: 10px;

                                .chat-client-info {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }

                .user-colors {
                    background-color: #86BB71;
                    color: #fff;
                }

                .coach-colors {
                    background-color: #E38968;
                    color: #fff;
                }

                .admin-colors {
                    background-color: #79b6f7;
                    color: #fff;
                }
            }

            .send-message-container {
                margin-top: auto;
                display: flex;
                justify-content: center;

                .send-message-inner {
                    width: 90%;
                    textarea {
                        width: 100%;
                        border-radius: 5px;
                        padding: 5px;
                        resize: none;
                    }

                    .message-hint {
                        font-size: smaller;
                        text-align: center;
                    }
    
                    .btn-send-panel {
                        display: flex;
                        justify-content: flex-end;

                        .loader {
                            position: relative;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    
        .user-info-container {
            min-width: 22%;
            padding-left: 10px;
            display: flex;
            flex-direction: column;

            .no-user {
                display: flex;
                justify-content: center;
            }

            .avatar-container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                h3 {
                    overflow-wrap: break-word;
                    width: 90%;
                    text-align: center;
                }

                .avatar {
                    border-radius: 50%;
                    max-width: 100px;
                    max-height: 100px;
                }

                .text-avatar {
                    border-radius: 50%;
                    width: 100px;
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 28px;
                    color: #fff;
                    background-color: #79b6f7;
                }

                .avatar-edit-container {
                    display: flex;
                    justify-content: center;
                    margin-top: 10px;

                    button {
                        margin-left: 10px;
                    }
                }
            }

            .row {
                margin-top: 10px;
                width: 100%;
                
                div:last-child {
                    overflow-wrap: break-word;
                }
            }

            .role-buttons {
                button {
                    margin-top: 8px;
                    margin-right: 5px;
                }
            }
        }
    }
}